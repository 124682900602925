import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';


interface ToastInput {
  type?: string;
  message?: string;
  show: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  
  private toasterSubject = new Subject<any>();

  constructor(private router: Router) {
  }

  // PUBLISH TOASTER
  sendToasterMessage(toaster: ToastInput) {
    this.toasterSubject.next(toaster);

    setTimeout(() => {
      this.toasterSubject.next({ show: false });
    }, 10000);
  }


  // LISTEN TOASTER CHANGE
  listenForToasterChange(): Observable<any> {
    return this.toasterSubject.asObservable();
  }

  showToastAndRoute(message: string, toastType: string, route: string): void {
    let toast = {
      message: message,
      type: toastType,
      show: true
    }
    setTimeout(() => {
      this.sendToasterMessage(toast);
    });
    this.router.navigate([route]);
  }

  showToast(message: string, toastType: string): void {
    let toast = {
      message: message,
      type: toastType,
      show: true
    }
    setTimeout(() => {
      this.sendToasterMessage(toast);
    });
  }

}
