// Error Messages
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';
export const NO_DATA_FOUND = 'No records found for your filter request.';

// API Responses
export const API_SUCCESS_STATUS = '200';
export const API_FAILURE_STATUS = '400';

//Month Filter object
export const monthFilterObject = [
  { month_id: 0, month_name: 'January' },
  { month_id: 1, month_name: 'February' },
  { month_id: 2, month_name: 'March' },
  { month_id: 3, month_name: 'April' },
  { month_id: 4, month_name: 'May' },
  { month_id: 5, month_name: 'June' },
  { month_id: 6, month_name: 'July' },
  { month_id: 7, month_name: 'August' },
  { month_id: 8, month_name: 'September' },
  { month_id: 9, month_name: 'October' },
  { month_id: 10, month_name: 'November' },
  { month_id: 11, month_name: 'December' }
]

export function generateYearFilterObject(): { year_id: number, year_name: number }[] {
  const currentYear = new Date().getFullYear();
  const yearFilterObject = [];

  for (let i = 0; i <= 3; i++) {
    yearFilterObject.push({ year_id: currentYear - i, year_name: currentYear - i });
  }
  return yearFilterObject;
}


export function payloadGenerator(filterObjects: any[], additionalObject: any[] = []): any {
  let payload: any = {};

  // Iterate over each group of filters
  filterObjects.forEach((filters) => {
    filters.forEach((filter: any) => {
      let key = filter.key;
      let value = filter.value;

      // Handle different types of filters
      switch (filter.type) {
        case 'text':
        case 'text-nospace':
        case 'text-alnum':
        case 'text-alnum-nospace':
        case 'text-alnum-address':
        case 'text-email':
        case 'text-phone-number':
        case 'number':
          payload[key] = value;
          break;
        case 'select':
          if (value !== '(All)') {
            // Check if multi_select is enabled, and handle accordingly
            if (filter.config.multi_select && Array.isArray(value)) {
              if (value.length > 0 && value[0] !== '(All)') {
                payload[key] = value;
              }
            } else {
              payload[key] = value;
            }
          }
          break;
        case 'checkbox':
          if (value) {
            payload[key] = filter.config.trueValue;
          }
          break;
        case 'new_line':
          // Ignore new_line type as it's for UI purposes only
          break;
      }
    });
  });

  // Add additionalObject fields
  if (additionalObject.length > 0) {
    additionalObject.forEach((additional) => {
      Object.keys(additional).forEach((key) => {
        if (additional[key] !== '(All)') {
          payload[key] = additional[key];
        }
      });
    });
  }

  return payload;
}

export function formPayloadGenerator(formList: any[], additionalObject: any[] = []): any {
  let payload: any = {};

  // Iterate over each group of filters
  formList.forEach((form) => {
    form.forEach(row => {
      row.forEach(filter => {
        let key = filter.key;
        let value = filter.value;

        // Handle different types of filters
        switch (filter.type) {
          case 'text':
          case 'text-nospace':
          case 'text-alnum':
          case 'text-alnum-nospace':
          case 'text-email':
          case 'text-alnum-address':
          case 'text-phone-number':
          case 'value':
          case 'number':
            payload[key] = value;
            break;
          case 'select':
            if (value !== '(All)') {
              // Check if multi_select is enabled, and handle accordingly
              if (filter.config.multi_select && Array.isArray(value)) {
                if (value.length > 0 && value[0] !== '(All)') {
                  payload[key] = value;
                }
              } else {
                payload[key] = value;
              }
            }
            break;
          case 'checkbox':
            if (value) {
              payload[key] = filter.config.trueValue;
            }
            break;
          case 'new_line':
            // Ignore new_line type as it's for UI purposes only
            break;
        }
      });
    });
  });

  // Add additionalObject fields
  if (additionalObject.length > 0) {
    additionalObject.forEach((additional) => {
      Object.keys(additional).forEach((key) => {
        if (additional[key] !== '(All)') {
          payload[key] = additional[key];
        }
      });
    });
  }

  return payload;
}

//Payload Generator Function
export function advanceFilterPayloadGenerator(filterObjects: { [key: string]: any }[][][], additionalObject: { [key: string]: string | number }[] = []): { [key: string]: string | number } {
  let payloadObject = {};
  filterObjects.forEach(column => {
    column.forEach(row => {
      row.forEach(filterObject => {
        if (filterObject && filterObject.length) {
          filterObject.forEach(filter => {
            if (filter.key && filter.value && !filter.config.ignore_filter) {
              if (filter.type === 'checkbox') {
                payloadObject[filter.key] = filter.config.trueValue;
              }
              else {
                payloadObject[filter.key] = typeof filter.value === 'string' ? filter.value.trim() : filter.value;
              }
            }
          });
        }
      });
    });
  });

  additionalObject.forEach(filterObj => {
    Object.keys(filterObj).forEach(obj => {
      payloadObject[obj] = additionalObject[obj];
    });
  });

  return payloadObject;
}

//Response data sorting function
export function sortResponseData(data: { [key: string]: any }[], key: string, order: string = 'desc', data_type: string): { [key: string]: any }[] {
  if (data && data.length && key) {
    try {
      if (data_type === 'number') {
        if (order === 'desc') {
          return data.sort((a, b) => parseFloat(b[key] == null ? 0 : b[key]) - parseFloat(a[key] == null ? 0 : a[key]));
        } else {
          return data.sort((a, b) => parseFloat(a[key] == null ? 0 : a[key]) - parseFloat(b[key] == null ? 0 : b[key]));
        }
      }
      else if (data_type === 'string') {
        if (order === 'desc') {
          return data.sort((a, b) => {
            const nameA = a[key] ? a[key].toLowerCase().trim() : '';
            const nameB = b[key] ? b[key].toLowerCase().trim() : '';
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
        } else {
          return data.sort((a, b) => {
            const nameA = b[key] ? b[key].toLowerCase().trim() : '';
            const nameB = a[key] ? a[key].toLowerCase().trim() : '';
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
        }
      }
      else if (data_type === 'date') {
        if (order === 'desc') {
          return data.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime());
        } else {
          return data.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
        }
      }
    } catch (error) {
      console.trace(error);
      return data;
    }
  }
  return data;
}
