<ng-template #popContent let-c="close" let-d="dismiss">
    <div class="modal-header mb-4">
        <h4 class="modal-title" id="modal-basic-title">Something went Wrong!</h4>
    </div>
    <div class="modal-body">
        <span>
            Something is wrong at our end. We have logged the issue and our team is notified for the same. In case of
            urgency, please raise your concerns to <strong><a [href]="'mailto:allergan_reporting@zs.com'"><span
                        class="email-link">ALLERGAN_REPORTING</span>.
                </a></strong>
                <br>
                <br>
                <div class="d-flex flex-row align-items-center">
                    <img src="assets/icons/error.svg" alt="error-icon" class="warning-icon me-2">
                    <b>Please do not use portal till further communication.</b>
                </div>
        </span>
    </div>
</ng-template>